<template>
  <div class="events-max-age-input">
    <el-input-number class="events-max-age-input__input" v-model="model" :label="label" :min="min" :max="max" size="mini" />
    <em class="events-max-age-input__days" v-text="$tp('common.older_n_days', model)"></em>
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';

@Component({
  props: {
    min: { type: Number, default: 0 },
    max: { type: Number, default: 9999 },
    value: { type: Number, required: true },
    objectsType: { type: String, required: true }
  }
})
export default class EventsMaxAgeInput extends Vue {
  get model() {
    return this.value;
  }

  set model(value) {
    this.$emit('input', value);
  }

  get label() {
    return this.$tf(`${this.objectsType}_events_cleanup_matched`);
  }
}
</script>

<style lang="stylus">
.events-max-age-input {
  display: flex;
  align-items: center;

  &__input > .el-input-number {
    &__increase, &__decrease {
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__days {
    font-style: normal;
    margin-left: 0.75em;
  }
}
</style>
